import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";

type estimatedEstateValuePayload = {
  estimatedEstateValue?: number;
};

const updateEstimatedEstateValueReducer = (
  state: PortfolioDataInterface,
  action: { payload: estimatedEstateValuePayload }
) => {
  const { payload } = action;
  state.estimatedEstateValue = payload.estimatedEstateValue;
};

export default updateEstimatedEstateValueReducer;

import {
  AgricultureItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";
import { AgricultureObject } from "../constants/types";

export type AgriculturePayload = {
  payload: AgricultureItemInterface;
};

const sumAll = (list: AgricultureItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deleteAgricultureReducer = (
  state: PortfolioDataInterface,
  { payload }: AgriculturePayload
): void => {
  if (!state.agricultureAssets) {
    state.agricultureAssets = {
      totalValue: 0,
      agricultureList: [],
    };
  }

  state.agricultureAssets.agricultureList =
    state.agricultureAssets.agricultureList?.filter(
      (agriculture) => agriculture.id !== payload.id
    );

  state.agricultureAssets.totalValue = sumAll(
    state.agricultureAssets.agricultureList
  );
};

export const updateAgricultureReducer = (
  state: PortfolioDataInterface,
  { payload }: AgriculturePayload
): void => {
  if (!state.agricultureAssets) {
    state.agricultureAssets = {
      totalValue: 0,
      agricultureList: [],
    };
  }

  const fundExist = state.agricultureAssets.agricultureList?.some(
    (agriculture) => agriculture.id === payload.id
  );

  if (fundExist) {
    state.agricultureAssets.agricultureList =
      state.agricultureAssets.agricultureList?.map((agriculture) => {
        if (agriculture.id === payload.id) {
          return payload;
        }

        return agriculture;
      });
  } else {
    state.agricultureAssets.agricultureList?.push(payload);
  }

  state.agricultureAssets.totalValue = sumAll(
    state.agricultureAssets.agricultureList
  );
};

export type BulkUpdatePayload = {
  payload: AgricultureObject;
};

export const bulkUpdateAgriculture = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.agricultureAssets = payload;
};

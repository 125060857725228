import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";

const currencyReducer = (
  state: PortfolioDataInterface,
  action: { payload: { currency?: string } }
) => {
  const { payload } = action;
  state.currency = payload.currency;
};

export default currencyReducer;

import { Currency } from "@ifgengineering/hip-app-domain/src";

export const ContextCurrencies: Record<Currency, string> = {
  gbp: "£",
  usd: "$",
  eur: "€",
  inr: "₹",
  aed: "AED",
  cad: "CA$",
  myr: "MYR",
};

import { Investor } from "@ifgengineering/client-invest-sdk";
import { createReducer } from "@reduxjs/toolkit";
import { fetchInvestorAsync, updateInvestorAsync } from "./investor.action";

interface InvestorState {
  neverLoaded: boolean;
  loading: boolean;
  data?: Partial<Investor>;
}

const initialState: InvestorState = {
  neverLoaded: true,
  loading: false,
};

export const investorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchInvestorAsync.pending, (state) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(fetchInvestorAsync.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        neverLoaded: false,
        loading: false,
        data: payload,
      };
      return state;
    })
    .addCase(updateInvestorAsync.pending, (state) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(updateInvestorAsync.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        data: payload,
      };
      return state;
    });
});

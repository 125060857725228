import {
  Investor,
  UpdateOnboardingAPIPayload,
} from "@ifgengineering/client-invest-sdk";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientInvestSdk, updateInvestor } from "@helpers/clientInvestSdk";

export const FETCH_INVESTOR = "FETCH_INVESTOR";
export const UPDATE_INVESTOR = "UPDATE_INVESTOR";

export const fetchInvestorAsync = createAsyncThunk<Investor>(
  FETCH_INVESTOR,
  async () => {
    const response = await clientInvestSdk.getInvestor();
    return response.data;
  }
);

export const updateInvestorAsync = createAsyncThunk<
  Investor,
  UpdateOnboardingAPIPayload
>(UPDATE_INVESTOR, async (payload): Promise<Investor> => {
  const result = await updateInvestor(payload);

  return result.data;
});

import { gql } from "@apollo/client";
import { UserInfoInterface } from "@ifgengineering/hip-app-domain/src";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../../state/index";
import { logoutAction } from "@components/Login/actions";
import { logException } from "@helpers/logException";

export const FETCH_USER_API = "FETCH_USER_API";
export const SIGNUP_API = "SIGNUP_API";

export const fetchUserAPI = createAsyncThunk<UserInfoInterface>(
  FETCH_USER_API,
  async () => {
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query {
            myUser {
              email
              appRecord {
                track
                wills
                zakat
                compare
              }
            }
          }
        `,
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.myUser;
    } catch (e: any) {
      if (
        e.networkError.result.errors.some(
          ({ statusCode }) => statusCode === 401
        )
      ) {
        store.dispatch(logoutAction());
      } else {
        toast.warn("Something went wrong. Try again or contact support");

        logException({
          tag: "fetchUserAPI",
          exception: e,
        });
      }
      throw Error(e);
    }
  }
);

import { BequestIndividualInterface } from "@ifgengineering/hip-app-domain/src";
import { WillsState } from "../../constants/types";

const updateIndividualInListReducer = (
  state: WillsState,
  action: {
    payload: BequestIndividualInterface;
  }
) => {
  const { payload } = action;
  const updatedIndividualList = state.will.bequests.bequestIndividuals?.map(
    (individual) =>
      individual.id == payload.id ? { ...individual, ...payload } : individual
  );
  state.will.bequests.bequestIndividuals = updatedIndividualList;
};

export default updateIndividualInListReducer;

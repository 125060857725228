import { createReducer } from "@reduxjs/toolkit";
import {
  fetchUserInfoAPI,
  isLoggedInAction,
  loginAction,
  logoutAction,
  signInAppleAction,
  signInGoogleAction,
  signupAppleAction,
  updateUserInfo,
} from "@components/Login/actions";
import {
  authResetStateReducer,
  authFulfilledReducer,
  authPendingReducer,
  authRejectedReducer,
  signInAppleFulfilledReducer,
} from "@components/Login/reducers";
import { initialState } from "@components/Login/constants/initialState";

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginAction.pending, authPendingReducer)
    .addCase(loginAction.rejected, authRejectedReducer)
    .addCase(loginAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.fulfilled, authFulfilledReducer)
    .addCase(isLoggedInAction.rejected, authResetStateReducer)
    .addCase(logoutAction.pending, authPendingReducer)
    .addCase(logoutAction.rejected, authRejectedReducer)
    .addCase(logoutAction.fulfilled, authResetStateReducer)
    .addCase(updateUserInfo, authFulfilledReducer)
    .addCase(fetchUserInfoAPI.pending, authPendingReducer)
    .addCase(fetchUserInfoAPI.fulfilled, authFulfilledReducer)
    .addCase(fetchUserInfoAPI.rejected, authRejectedReducer)
    .addCase(signInGoogleAction.pending, authPendingReducer)
    .addCase(signInGoogleAction.fulfilled, authFulfilledReducer)
    .addCase(signInGoogleAction.rejected, authRejectedReducer)
    .addCase(signupAppleAction.pending, authPendingReducer)
    .addCase(signupAppleAction.fulfilled, authFulfilledReducer)
    .addCase(signupAppleAction.rejected, authRejectedReducer)
    .addCase(signInAppleAction.pending, authPendingReducer)
    .addCase(signInAppleAction.fulfilled, signInAppleFulfilledReducer)
    .addCase(signInAppleAction.rejected, authRejectedReducer);
});

export default authReducer;

import { Zakat } from "@ifgengineering/client-invest-sdk";
import { createReducer } from "@reduxjs/toolkit";
import {
  fetchNisabAsync,
  fetchZakatAsync,
  createZakatAssetAsync,
  updateZakatAssetAsync,
  deleteZakatAssetAsync,
  updateZakatLiabilityAsync,
  createZakatLiabilityAsync,
  deleteZakatLiabilityAsync,
  updateZakatTopUp,
  updateZakatPaymentAmount,
} from "./zakat.action";

interface ZakatV2State {
  neverLoaded: boolean;
  loading: boolean;
  data?: Zakat;
  topUp?: number;
  paymentAmount?: number;
  nisab?: number;
}

const initialState: ZakatV2State = {
  neverLoaded: true,
  loading: false,
};

export const zakatReducerV2 = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchNisabAsync.fulfilled, (state, { payload }) => {
      state.nisab = payload;
    })
    .addCase(fetchZakatAsync.pending, (state) => {
      state = {
        ...state,
        loading: true,
      };
      return state;
    })
    .addCase(fetchZakatAsync.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        neverLoaded: false,
        loading: false,
        data: payload,
      };
      return state;
    })
    .addCase(updateZakatTopUp, (state, { payload }) => {
      state.topUp = payload;
    })
    .addCase(updateZakatPaymentAmount, (state, { payload }) => {
      state.paymentAmount = payload;
    })
    .addCase(createZakatAssetAsync.fulfilled, (state, { payload }) => {
      if (state.data) {
        state.data.assets = [...(state.data.assets || []), payload];
      }
    })
    .addCase(updateZakatAssetAsync.fulfilled, (state, { payload }) => {
      if (state.data?.assets) {
        state.data.assets = state.data.assets.map((asset) =>
          asset.id === payload.id ? payload : asset
        );
      }
    })
    .addCase(deleteZakatAssetAsync.fulfilled, (state, { payload }) => {
      if (state.data?.assets) {
        state.data.assets = state.data.assets.filter(
          (asset) => asset.id !== payload
        );
      }
    })
    .addCase(createZakatLiabilityAsync.fulfilled, (state, { payload }) => {
      if (state.data) {
        state.data.liabilities = [...(state.data.liabilities || []), payload];
      }
    })
    .addCase(updateZakatLiabilityAsync.fulfilled, (state, { payload }) => {
      if (state.data?.liabilities) {
        state.data.liabilities = state.data.liabilities.map((liability) =>
          liability.id === payload.id ? payload : liability
        );
      }
    })
    .addCase(deleteZakatLiabilityAsync.fulfilled, (state, { payload }) => {
      if (state.data?.liabilities) {
        state.data.liabilities = state.data.liabilities.filter(
          (liability) => liability.id !== payload
        );
      }
    });
});

export const FETCH_NISAB = "FETCH_NISAB";
export const FETCH_ZAKAT = "FETCH_ZAKAT";
export const UPDATE_ZAKAT = "UPDATE_ZAKAT";
export const CREATE_ZAKAT_ASSET = "CREATE_ZAKAT_ASSET";
export const UPDATE_ZAKAT_ASSET = "UPDATE_ZAKAT_ASSET";
export const DELETE_ZAKAT_ASSET = "DELETE_ZAKAT_ASSET";
export const CREATE_ZAKAT_LIABILITY = "CREATE_ZAKAT_LIABILITY";
export const UPDATE_ZAKAT_LIABILITY = "UPDATE_ZAKAT_LIABILITY";
export const DELETE_ZAKAT_LIABILITY = "DELETE_ZAKAT_LIABILITY";

// no api calls for these
export const UPDATE_ZAKAT_TOP_UP = "UPDATE_ZAKAT_TOP_UP";
export const UPDATE_ZAKAT_PAYMENT_AMOUNT = "UPDATE_ZAKAT_PAYMENT_AMOUNT";

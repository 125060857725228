import React, { FC, useContext } from "react";
import { useMatch } from "@reach/router";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import { AuthComponentsContext } from "../Context";
import { SocialAuth, AppleAuthProps } from "./pages/Signin/types";
import SignupApple from "./pages/SignupApple";

export interface AuthWidgetProps {
  authRedirect: string;
  appleLogin?: AppleAuthProps;
  googleLogin?: SocialAuth;
}

const Widget: FC<AuthWidgetProps> = ({
  authRedirect,
  appleLogin,
  googleLogin,
}) => {
  const params = useMatch("/auth/:page");
  const { isMagicCodeEnabled } = useContext(AuthComponentsContext);

  const renderContent = () => {
    switch (params?.page) {
      case "signup":
        return (
          <Signup
            signupRedirect={authRedirect}
            appleLogin={appleLogin}
            googleLogin={googleLogin}
          />
        );
      case "signup-apple":
        return <SignupApple onRegister={appleLogin?.onSignup} />;
      case "forgot-password":
        return <ForgotPassword />;
      case "reset-password":
        return <PasswordReset />;
      case "verify-email":
        return <VerifyEmail />;
      case "login":
      default:
        return (
          <Signin
            loginRedirect={authRedirect}
            enableMagicCode={isMagicCodeEnabled}
            appleLogin={appleLogin}
            googleLogin={googleLogin}
          />
        );
    }
  };

  return renderContent();
};

export default Widget;

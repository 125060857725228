import { BequestInterface } from "@ifgengineering/hip-app-domain/src";
import { WillsState } from "../../constants/types";

const updateBequestsReducer = (
  state: WillsState,
  action: {
    payload: BequestInterface;
  }
) => {
  state.will.bequests = {
    ...action.payload,
  };
};

export default updateBequestsReducer;

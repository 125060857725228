import React, { FC, useContext } from "react";
import Widget, { AuthWidgetProps } from "../Widget";
import { RouteComponentProps } from "@reach/router";
import LayoutShell, { LayoutShellProps } from "../Widget/LayoutShell";
import { AuthComponentsContext } from "../Context";

type AuthPageProps = LayoutShellProps & AuthWidgetProps;

const AuthPage: FC<RouteComponentProps & AuthPageProps> = ({
  authRedirect,
  Promo,
  isCharity,
  appleLogin,
  googleLogin,
  ...layoutProps
}) => {
  const { isLayoutBEnabled } = useContext(AuthComponentsContext);

  return (
    <LayoutShell
      Promo={Promo}
      isCharity={isCharity}
      isLayoutBEnabled={isLayoutBEnabled}
      {...layoutProps}
    >
      <Widget
        authRedirect={authRedirect}
        appleLogin={appleLogin}
        googleLogin={googleLogin}
      />
    </LayoutShell>
  );
};

export default AuthPage;

import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { store } from "../index";
import { logoutAction } from "../../../components/Login/actions";
import { CryptoProductInterface } from "@ifgengineering/hip-app-domain/src";
import { logException } from "@helpers/logException";

export const FETCH_CRYPTO_PRODUCTS_API = "FETCH_CRYPTO_PRODUCTS_API";

export const fetchCryptoProducts = createAsyncThunk<CryptoProductInterface[]>(
  FETCH_CRYPTO_PRODUCTS_API,
  async () => {
    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query {
            cryptoProducts {
              id
              name
              rank
              ticker
              categories
              isHalal
              whitepaperLink
              shariahAnalysis
              buyLink
              isIfgHolding
            }
          }
        `,
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.cryptoProducts;
    } catch (e: any) {
      if (
        e.networkError.result.errors.some(
          ({ statusCode }: { statusCode: number }) => statusCode === 401
        )
      ) {
        store.dispatch(logoutAction());
      } else {
        logException({
          tag: "fetchCryptoProducts",
          exception: e,
        });

        toast.warn("Something went wrong. Try again or contact support");
        throw Error(e);
      }
    }
  }
);

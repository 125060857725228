import { ZakatCharityDetailedInterface } from "@ifgengineering/hip-app-domain/src";
import { PartialZakatAllocationInput, ZakatAllocationObject } from "../types";
import { ZakatState } from "./reducers";

export const createUpdateCharityAllocationReducer = (
  state: ZakatState,
  {
    payload,
  }: {
    payload: PartialZakatAllocationInput;
  }
): ZakatState => {
  const { charityAllocations, charities } = state;
  const { percentage, zakatCharityId } = payload;

  const charity = charities.find(
    (charity) => charity.id === zakatCharityId
  ) as ZakatCharityDetailedInterface;
  const existingAllocation = charityAllocations.find(
    (allocation) => allocation.zakatCharity.id === payload.zakatCharityId
  );

  if (!existingAllocation) {
    return {
      ...state,
      charityAllocations: [
        ...charityAllocations,
        {
          percentage,
          zakatCharity: {
            id: charity.id,
            active: charity.active,
          },
        },
      ],
    };
  }

  const updatedCharities = charityAllocations.map((allocation) => {
    if (allocation.zakatCharity.id === zakatCharityId) {
      return {
        ...allocation,
        percentage,
      };
    }

    return allocation;
  });

  return {
    ...state,
    charityAllocations: updatedCharities as ZakatAllocationObject[],
  };
};

import { AxiosStatic } from "axios";

const forgotPassword =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (email: string, origin?: string): Promise<void> => {
    await appAxios.post(`${serverURL}/auth/forgot-password`, {
      email,
      origin,
    });
  };

export default forgotPassword;

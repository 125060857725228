import {
  InitializeApi,
  Investor,
  UpdateOnboardingAPIPayload,
} from "@ifgengineering/client-invest-sdk";
import { CUR8_API } from "gatsby-env-variables";
import axios, { AxiosResponse } from "axios";

export const clientInvestSdk = InitializeApi({
  serverURL: CUR8_API,
  appAxios: axios as any,
});

export const updateInvestor = async (
  data: UpdateOnboardingAPIPayload
): Promise<AxiosResponse<Investor>> => {
  return axios.put(`${CUR8_API}/investor`, data, {
    withCredentials: true,
  });
};

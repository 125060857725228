import { createReducer } from "@reduxjs/toolkit";
import {
  createPartnerCharityDonation,
  fetchPartnerCharityDonation,
  updateCharityDonation,
  updateCharityStore,
} from "@components/Charity/actions";
import {
  PartnerCharityDetailedInterface,
  PartnerCharityDonationInterface,
} from "@ifgengineering/hip-app-domain/src";
import { updateCharityAllocation } from "./charity.action";

export type PartnerCharityInterfaceExtra = PartnerCharityDetailedInterface & {
  donation?: PartnerCharityDonationInterface;
};

export const initialState: PartnerCharityInterfaceExtra | null = {
  id: "",
  key: "",
  willTemplateCopy: "",
  active: false,
  displayName: "",
  logoKey: "",
  registeredName: "",
  address: {},
};

export const charityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateCharityStore, (state, { payload }) => {
      state = {
        ...state,
        ...payload,
      };

      return state;
    })
    .addCase(fetchPartnerCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: payload,
      };
      return state;
    })
    .addCase(createPartnerCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: {
          ...state.donation,
          ...payload,
        },
      };

      return state;
    })
    .addCase(updateCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: {
          ...state.donation,
          ...payload,
        },
      };

      return state;
    });
});

export const initialStateV2: {
  loading: boolean;
  neverLoaded: boolean;
  data: {
    id: string;
    displayName: string;
    logoKey: string;
    allocation: number;
  };
} = {
  loading: false,
  neverLoaded: false,
  data: {
    id: "6",
    displayName: "IFG Zakat Impact Fund",
    logoKey:
      "https://s3.amazonaws.com/img.islamicfinanceguru.com/27cfd221-50cf-4f65-b9de-e98e9e0f9516.webp",
    allocation: 100, // percentage
  },
};

export const charityReducerV2 = createReducer(initialStateV2, (builder) => {
  builder.addCase(updateCharityAllocation, (state, { payload }) => {
    state.data.allocation = payload;
  });
});

import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import {
  BequestCharityInterface,
  BequestIndividualInterface,
  BequestInterface,
} from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  BQ_CREATE_BEQUEST_API,
  BQ_CREATE_INDIVIDUAL_BEQUEST_API,
  BQ_UPDATE_BEQUEST_API,
  BQ_UPDATE_CHARITY_BEQUEST_API,
  BQ_UPDATE_INDIVIDUAL_BEQUEST_API,
  BQ_DELETE_INDIVIDUAL_BEQUEST_API,
  BQ_DELETE_CHARITY_BEQUEST_API,
} from "../constants/actionTypes";
import {
  BEQUEST_CHARITY_DATA,
  BEQUEST_DATA,
  BEQUEST_INDIVIDUAL_DATA,
} from "../gql_fragments/bequest";

export const createBequestAPI = createAsyncThunk<BequestInterface, string>(
  BQ_CREATE_BEQUEST_API,
  async (email) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          ${BEQUEST_DATA}
          mutation ($email: String!) {
            createBequest(email: $email) {
              ...BequestData
            }
          }
        `,
        variables: {
          email,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
      }
      return response.data?.createBequest;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "createBequestAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

export const updateBequestAPI = createAsyncThunk<
  BequestInterface,
  {
    data: {
      id?: number;
      memberDisabled?: boolean;
      memberReceivingTestedBenefits?: boolean;
    };
  }
>(BQ_UPDATE_BEQUEST_API, async ({ data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        ${BEQUEST_DATA}
        mutation ($data: UpdateBequestInput!) {
          editBequest(data: $data) {
            ...BequestData
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }
    return response.data?.editBequest;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateBequestAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const createIndividualBequestAPI = createAsyncThunk<
  BequestIndividualInterface,
  {
    data: BequestIndividualInterface;
    email: string;
  }
>(
  BQ_CREATE_INDIVIDUAL_BEQUEST_API,
  async ({ data, email }, { dispatch, getState }) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          ${BEQUEST_INDIVIDUAL_DATA}
          mutation ($data: CreateBequestIndividual!, $email: String!) {
            createIndividualBequest(data: $data, email: $email) {
              ...BequestIndividualData
            }
          }
        `,
        variables: {
          data,
          email,
        },
      });
      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
      return response.data.createIndividualBequest;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "createIndividualBequestAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

export const updateIndividualBequestAPI = createAsyncThunk<
  BequestIndividualInterface,
  {
    data: BequestIndividualInterface;
  }
>(BQ_UPDATE_INDIVIDUAL_BEQUEST_API, async ({ data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        ${BEQUEST_INDIVIDUAL_DATA}
        mutation ($data: UpdateBequestIndividual!) {
          editIndividualBequest(data: $data) {
            ...BequestIndividualData
          }
        }
      `,
      variables: {
        data,
      },
    });
    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }
    return response.data.editIndividualBequest;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateIndividualBequestAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteIndividualBequestAPI = createAsyncThunk<
  void,
  number | undefined
>(BQ_DELETE_INDIVIDUAL_BEQUEST_API, async (id) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($id: Float!) {
          deleteIndividualBequest(id: $id) {
            message
          }
        }
      `,
      variables: {
        id,
      },
    });
    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }
    return response.data.deleteIndividualBequest;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "deleteIndividualBequestAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const createCharityBequestAPI = createAsyncThunk<
  BequestCharityInterface,
  {
    data: BequestCharityInterface;
    email: string;
    partnerCharityCauseIds?: number[];
    partnerCharityId?: string;
  }
>(
  BQ_CREATE_BEQUEST_API,
  async ({ data, email, partnerCharityCauseIds, partnerCharityId }) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          ${BEQUEST_CHARITY_DATA}
          mutation (
            $data: CreateBequestCharity!
            $email: String!
            $partnerCharityCauseIds: [Float]
            $partnerCharityId: String
          ) {
            createCharityBequest(
              data: $data
              email: $email
              partnerCharityCauseIds: $partnerCharityCauseIds
              partnerCharityId: $partnerCharityId
            ) {
              ...BequestCharityData
            }
          }
        `,
        variables: {
          data,
          email,
          partnerCharityCauseIds,
          partnerCharityId,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
      return response.data.createCharityBequest;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "createCharityBequestAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

export const updateCharityBequestAPI = createAsyncThunk<
  BequestCharityInterface,
  {
    data: BequestCharityInterface;
    partnerCharityCauseIds?: number[];
    partnerCharityId?: string;
  }
>(
  BQ_UPDATE_CHARITY_BEQUEST_API,
  async ({ data, partnerCharityCauseIds, partnerCharityId }) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          ${BEQUEST_CHARITY_DATA}
          mutation (
            $data: UpdateBequestCharity!
            $partnerCharityCauseIds: [Float]
            $partnerCharityId: String
          ) {
            editCharityBequest(
              data: $data
              partnerCharityCauseIds: $partnerCharityCauseIds
              partnerCharityId: $partnerCharityId
            ) {
              ...BequestCharityData
            }
          }
        `,
        variables: {
          data,
          partnerCharityCauseIds,
          partnerCharityId,
        },
      });
      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
      return response.data.editCharityBequest;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "updateCharityBequestAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

export const deleteCharityBequestAPI = createAsyncThunk<
  void,
  number | undefined
>(BQ_DELETE_CHARITY_BEQUEST_API, async (id) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($id: Float!) {
          deleteCharityBequest(id: $id) {
            message
          }
        }
      `,
      variables: {
        id,
      },
    });
    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }
    return response.data.deleteCharityBequest;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "deleteCharityBequestAPI",
      exception: e,
    });

    throw Error(e);
  }
});

import { initialState } from "./constants/initialState";
import { ActionPayload, AuthState } from "./constants/types";

export const authPendingReducer = (state: AuthState): void => {
  state.loading = true;
};

export const authRejectedReducer = (state: AuthState): void => {
  state.loading = false;
};

export const authFulfilledReducer = (
  state: AuthState,
  action: ActionPayload
): void => {
  state.loading = false;
  state.isAuthenticated = true;
  state.neverLoaded = false;
  state.user = {
    ...state.user,
    id: action.payload?.user_id,
    email: action.payload?.email,
    appRecord: {
      ...state.user.appRecord,
      ...action.payload?.appRecord,
    },
    ...action.payload,
  };
};

export const authResetStateReducer = (): AuthState => initialState;

// Added specifically to handle private relay email
export const signInAppleFulfilledReducer = (
  state: AuthState,
  action: ActionPayload
): void => {
  const isPrivateRelayEmail = action.payload?.isPrivateRelayEmail;
  const isAuthenticated = isPrivateRelayEmail ? false : true;

  state.loading = false;
  state.isAuthenticated = isAuthenticated;
  state.neverLoaded = false;
  state.user = {
    ...state.user,
    email: action.payload?.email,
    appRecord: {
      ...state.user.appRecord,
      ...action.payload?.appRecord,
    },
    ...action.payload,
  };
};

import { BequestIndividualInterface } from "@ifgengineering/hip-app-domain/src";
import { Selector } from "@willsStore/utils/types";
import { UserInfoObject, WillsState } from "./constants/types";

export const willSelector: Selector<WillsState["will"]> = (state) =>
  state.resources.wills?.will;

export const willStatusSelector: Selector<WillsState["will"]["status"]> = (
  state
) => state.resources.wills?.will?.status;

export const willIsCompletePaidSelector: Selector<boolean> = (state) => {
  const status = willStatusSelector(state);
  return status === "Complete - Paid" || status === "Complete - Will Generated";
};

export const userInfoSelector: Selector<WillsState["will"]["userInfo"]> = (
  state
): UserInfoObject => state.resources.wills?.will?.userInfo;

export const executorListSelector: Selector<
  WillsState["will"]["overseer"]["executionerList"]
> = (state) => state.resources.wills?.will?.overseer?.executionerList || [];

export const guardianListSelector: Selector<
  WillsState["will"]["overseer"]["guardianList"]
> = (state) => state.resources.wills?.will?.overseer?.guardianList || [];

export const departingMessageSelector: Selector<string> = (state) =>
  // In the willsState domain departingMessage is defined as String
  // to avoid keep casting and overiting in the component I'm casting it here
  state.resources.wills?.will?.departingMessage as string;

export const bqIndividualSelector: Selector<WillsState["bq_individual"]> = (
  state
) => state.resources.wills.bq_individual;

export const bqExistingInheritorsSelector: Selector<
  BequestIndividualInterface[]
> = (state) =>
  state.resources.wills.will.family?.familyMembers?.map((member) => ({
    id: member.id,
    firstName: member.firstName,
    lastName: member.lastName,
    emailAddress: member.email,
    relationship: member.familyMemberType,
    address: {},
    itemsToLeave: [],
    islamicInheritor: true,
  }));

export const bqCharitySelector: Selector<WillsState["bq_charity"]> = (state) =>
  state.resources.wills?.bq_charity;

export const bqIndividualsSelector: Selector<
  WillsState["will"]["bequests"]["bequestIndividuals"]
> = (state) => state.resources?.wills?.will?.bequests?.bequestIndividuals || [];

export const bqCharitiesSelector: Selector<
  WillsState["will"]["bequests"]["bequestCharity"]
> = (state) => state.resources?.wills?.will?.bequests?.bequestCharity || [];

export const bqSelector: Selector<WillsState["will"]["bequests"]> = (state) =>
  state.resources?.wills?.will?.bequests;

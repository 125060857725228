import React, { useEffect, useState } from "react";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import styled, { keyframes } from "styled-components";
import Text from "../styled/Text";
import Heading from "../styled/Heading";
import Container from "../styled/Container";
import ErrorContainer from "../styled/ErrorContainer";
import { theme } from "../../../../styles/legacy-theme";

const InputWrapper = styled.div`
  position: relative;
  justify-content: flex-end;
  display: flex;
  margin: 8px 0 0 0;
  background: white;
  border-radius: 12px;

  & input[type="text"] {
    opacity: 0;
    color: transparent;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    height: 3rem;
    border-radius: 12px;
    padding: 13px;
    outline: transparent;
  }
`;

const PerCentValue = styled.div`
  font-size: 1.18rem;
  font-weight: 600;
  color: ${theme.colors.Font02};
`;

const CurrencySymbol = styled.div`
  font-size: 1.18rem;
  color: ${theme.colors.Font02};
`;

const blink = keyframes`
 0% { color: transparent; }
 50% { color:${theme.colors.Font01}; }
`;

const BlinkyCaret = styled.span`
  font-weight: 100;
  font-size: 1.18rem;
  // margin: 0 -2px 0 -2px;
  animation: 1s ${blink} step-end infinite;
`;

type FakeInputProp = {
  hasError: boolean;
};

const FakeInput = styled.div<FakeInputProp>`
  border-radius: 12px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? theme.colors.ErrorRed : theme.colors.Grey02};
  box-sizing: border-box;
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  overflow: hidden;
  padding: 9px;
  position: absolute;
  width: 100%;
`;

export type MoneyInputProps = {
  description?: string;
  errorMessage?: string;
  heading?: string;
  value?: string;
  max?: string;
  currency?: string;
  onChange: (value: string) => void;
};

const MoneyInput = ({
  description,
  errorMessage,
  heading,
  onChange,
  value = "",
  currency = "£",
  max,
}: MoneyInputProps): JSX.Element => {
  const defaultValue = "0.00";
  const [innerValue, setInnerValue] = useState<string>(value);
  const [formattedValue, setFormattedValue] = useState<string>(defaultValue);
  const [showBlink, setShowBlink] = useState<boolean>(false);

  useEffect(() => {
    const formattedValue = formatValue({
      value: value,
      groupSeparator: ",",
      decimalSeparator: ".",
    });

    setInnerValue(value);
    setFormattedValue(formattedValue);
  }, [value]);

  const validateValue = (imputedValue?: string) => {
    if (!max || !imputedValue) {
      return imputedValue;
    }

    return Number(imputedValue) > Number(max) ? max : imputedValue;
  };

  const onChangeHandler = (imputedValue?: string) => {
    const validatedValue = validateValue(imputedValue);
    const formattedValue = formatValue({
      value: validatedValue,
      groupSeparator: ",",
      decimalSeparator: ".",
    });

    onChange(validatedValue || "");
    setInnerValue(validatedValue || "");
    setFormattedValue(formattedValue);
  };

  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {description && <Text>{description}</Text>}
      <InputWrapper>
        <FakeInput hasError={Boolean(errorMessage)}>
          <CurrencySymbol>{currency}</CurrencySymbol>
          <PerCentValue>{formattedValue}</PerCentValue>
          {showBlink && <BlinkyCaret>|</BlinkyCaret>}
        </FakeInput>
        <CurrencyInput
          defaultValue="0.00"
          decimalsLimit={2}
          value={innerValue}
          onClick={() => {
            setShowBlink(true);
          }}
          onFocus={() => {
            setShowBlink(true);
          }}
          onBlur={() => {
            setShowBlink(false);
            setFormattedValue(formattedValue || defaultValue);
            setInnerValue(innerValue || defaultValue);
            onChangeHandler(innerValue || defaultValue);
          }}
          onValueChange={(value) => onChangeHandler(value)}
        />
      </InputWrapper>
      {errorMessage && (
        <ErrorContainer>
          {errorMessage && (
            <Text hasError={!!errorMessage}>{errorMessage}</Text>
          )}
        </ErrorContainer>
      )}
    </Container>
  );
};

export default MoneyInput;

import { gql } from "@apollo/client";
import { ZakatCharityDetailedInterface } from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { FETCH_ZAKAT_CHARITY_API } from "../constants/actionTypes";
import { logException } from "@helpers/logException";

export const fetchZakatCharityAPI = createAsyncThunk<
  ZakatCharityDetailedInterface[]
>(FETCH_ZAKAT_CHARITY_API, async () => {
  try {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          zakatCharities {
            id
            displayName
            logoKey
            active
            zakatPriority
          }
        }
      `,
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.zakatCharities;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "fetchZakatCharityAPI",
      exception: e,
    });

    throw Error(e);
  }
});

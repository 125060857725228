import { AxiosResponse } from "axios";
import authSdk from "../authSDK";

const getRefreshToken = async (): Promise<AxiosResponse<never> | null> => {
  return authSdk
    .refreshAccessToken()
    .then((response) => response)
    .catch((error) => error);
};

export default getRefreshToken;

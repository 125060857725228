import {
  PensionItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";

export type PensionPayload = {
  payload: PensionItemInterface;
};

const sumAll = (list: PensionItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.totalValue, 0);
};

export const deletePensionReducer = (
  state: PortfolioDataInterface,
  { payload }: PensionPayload
): void => {
  if (!state.pensions) {
    state.pensions = {
      totalValue: 0,
      pensionList: [],
    };
  }

  state.pensions.pensionList = state.pensions.pensionList?.filter(
    (pension) => pension.id !== payload.id
  );

  state.pensions.totalValue = sumAll(state.pensions.pensionList);
};

export const updatePensionReducer = (
  state: PortfolioDataInterface,
  { payload }: PensionPayload
): void => {
  if (!state.pensions) {
    state.pensions = {
      totalValue: 0,
      pensionList: [],
    };
  }

  const pensionExist = state.pensions.pensionList?.some(
    (pension) => pension.id === payload.id
  );

  if (pensionExist) {
    state.pensions.pensionList = state.pensions.pensionList?.map((pension) => {
      if (pension.id === payload.id) {
        return payload;
      }

      return pension;
    });
  } else {
    state.pensions.pensionList?.push(payload);
  }

  state.pensions.totalValue = sumAll(state.pensions.pensionList);
};

export type BulkUpdatePayload = {
  payload: PensionItemInterface;
};

export const bulkUpdatePension = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.pensions = payload;
};

import { ShortlistItem } from "./shortlist.types";
import { client } from "../../../graphql";
import { gql } from "@apollo/client";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { CompareProductInterface } from "@ifgengineering/hip-app-domain/src";
import { logoutAction } from "@components/Login/actions";
import { logException } from "@helpers/logException";

export const ADD_ITEM_TO_SHORTLIST_API = "ADD_ITEM_TO_SHORTLIST_API";
export const ADD_ITEM_TO_SHORTLIST_STORE = "ADD_ITEM_TO_SHORTLIST_STORE";
export const REMOVE_ITEM_FROM_SHORTLIST_API = "REMOVE_ITEM_FROM_SHORTLIST_API";
export const REMOVE_ITEM_FROM_SHORTLIST_STORE =
  "REMOVE_ITEM_FROM_SHORTLIST_STORE";
export const FETCH_SHORLIST_ITEMS_API = "FETCH_SHORLIST_ITEMS_API";
export const ADD_ITEMS_TO_SHORTLIST_STORE = "ADD_ITEMS_TO_SHORTLIST_STORE";

export const addItemToShortlistStore = createAction<ShortlistItem>(
  ADD_ITEM_TO_SHORTLIST_STORE
);

export const removeItemFromShortlistStore = createAction<{
  id: string;
}>(REMOVE_ITEM_FROM_SHORTLIST_STORE);

export const addItemsToShortlistStore = createAction<ShortlistItem[]>(
  ADD_ITEMS_TO_SHORTLIST_STORE
);

export const addItemToShortlistAPI = createAsyncThunk<
  ShortlistItem,
  {
    product: CompareProductInterface;
    allocation: number;
  }
>(ADD_ITEM_TO_SHORTLIST_API, async ({ product, allocation }, { dispatch }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($productID: String!, $allocation: Float!) {
          addShortlistItem(
            data: { productID: $productID, allocation: $allocation }
          ) {
            id
            product {
              id
              affiliateLink
              assetClass
              availableInCountry
              category
              logo
              description
              ifgAnalysis
              investmentReturn
              liquidity
              minimumInvestment
              name
              regulated
              risk
              tags
            }
            allocation
          }
        }
      `,
      variables: {
        productID: product.id,
        allocation,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.addShortlistItem;
  } catch (e: any) {
    if (
      e.networkError.result.errors.some(({ statusCode }) => statusCode === 401)
    ) {
      dispatch(logoutAction());
    } else {
      logException({
        exception: e,
      });

      toast.warn("Something went wrong. Try again or contact support");
    }

    return { errors: e.networkError.result.errors };
  }
});

export const removeItemFromShortlistAPI = createAsyncThunk<
  void,
  {
    id: string;
  }
>(REMOVE_ITEM_FROM_SHORTLIST_API, async ({ id }) => {
  try {
    const response = await client.mutate<{
      removeShortlistItem: { message: string };
    }>({
      mutation: gql`
        mutation ($productID: String!) {
          removeShortlistItem(data: { productID: $productID }) {
            message
          }
        }
      `,
      variables: {
        productID: id,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "removeItemFromShortlistAPI",
      exception: e,
    });

    throw Error(e);
  }
});

type fetchShortlistItemsAPIReturn = {
  errors?: Array<{
    message: string;
    name: string;
    statusCode: number;
  }>;
  data?: ShortlistItem[];
};

export const fetchShortlistItemsAPI =
  createAsyncThunk<fetchShortlistItemsAPIReturn>(
    FETCH_SHORLIST_ITEMS_API,
    async (_, { dispatch }) => {
      try {
        const response = await client.query({
          fetchPolicy: "no-cache",
          query: gql`
            query {
              shortlist {
                id
                product {
                  id
                  name
                  active
                  logo
                  description
                  affiliateLink
                  assetClass
                  category
                  ifgAnalysis
                  liquidity
                  investmentReturn
                  minimumInvestment
                  regulated
                  risk
                  tags
                  availableInCountry
                  priority
                }
                allocation
              }
            }
          `,
        });

        const {
          errors,
          data: { shortlist: data },
        } = response;
        return { data, errors };
      } catch (e: any) {
        if (
          e.networkError.result.errors.some(
            ({ statusCode }: { statusCode: number }) => statusCode !== 401
          )
        ) {
          logException({
            tag: "fetchShortlistItemsAPI",
            exception: e,
          });

          toast.warn("Something went wrong. Try again or contact support");
          throw Error(e);
        }

        return { errors: e.networkError.result.errors };
      }
    }
  );

import {
  CreateZakatAssetRequest,
  CreateZakatLiabilityRequest,
  UpdateZakatAssetRequest,
  UpdateZakatLiabilityRequest,
  UpdateZakatRequest,
  Zakat,
  ZakatAsset,
  ZakatLiability,
} from "@ifgengineering/client-invest-sdk";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { clientInvestSdk } from "@helpers/clientInvestSdk";
import {
  FETCH_NISAB,
  FETCH_ZAKAT,
  UPDATE_ZAKAT,
  CREATE_ZAKAT_ASSET,
  UPDATE_ZAKAT_ASSET,
  DELETE_ZAKAT_ASSET,
  CREATE_ZAKAT_LIABILITY,
  UPDATE_ZAKAT_LIABILITY,
  DELETE_ZAKAT_LIABILITY,
  UPDATE_ZAKAT_TOP_UP,
  UPDATE_ZAKAT_PAYMENT_AMOUNT,
} from "./zakat.types";

export const fetchNisabAsync = createAsyncThunk<number>(
  FETCH_NISAB,
  async () => {
    const response = await clientInvestSdk.getNisab();
    return response.data;
  }
);

export const fetchZakatAsync = createAsyncThunk<Zakat>(
  FETCH_ZAKAT,
  async () => {
    const response = await clientInvestSdk.getZakat();
    return response.data;
  }
);

export const updateZakatAsync = createAsyncThunk<Zakat, UpdateZakatRequest>(
  UPDATE_ZAKAT,
  async (payload) => {
    const response = await clientInvestSdk.updateZakat(payload);
    return response.data;
  }
);

export const createZakatAssetAsync = createAsyncThunk<
  ZakatAsset,
  CreateZakatAssetRequest
>(CREATE_ZAKAT_ASSET, async (payload) => {
  const response = await clientInvestSdk.createZakatAsset(payload);
  return response.data;
});

export const updateZakatAssetAsync = createAsyncThunk<
  ZakatAsset,
  { assetId: string; data: UpdateZakatAssetRequest }
>(UPDATE_ZAKAT_ASSET, async ({ assetId, data }) => {
  const response = await clientInvestSdk.updateZakatAsset(assetId, data);
  return response.data;
});

export const deleteZakatAssetAsync = createAsyncThunk<string, string>(
  DELETE_ZAKAT_ASSET,
  async (assetId) => {
    await clientInvestSdk.deleteZakatAsset(assetId);
    return assetId;
  }
);

export const createZakatLiabilityAsync = createAsyncThunk<
  ZakatLiability,
  CreateZakatLiabilityRequest
>(CREATE_ZAKAT_LIABILITY, async (payload) => {
  const response = await clientInvestSdk.createZakatLiability(payload);
  return response.data;
});

export const updateZakatLiabilityAsync = createAsyncThunk<
  ZakatLiability,
  { liabilityId: string; data: UpdateZakatLiabilityRequest }
>(UPDATE_ZAKAT_LIABILITY, async ({ liabilityId, data }) => {
  const response = await clientInvestSdk.updateZakatLiability(
    liabilityId,
    data
  );
  return response.data;
});

export const deleteZakatLiabilityAsync = createAsyncThunk<string, string>(
  DELETE_ZAKAT_LIABILITY,
  async (liabilityId) => {
    await clientInvestSdk.deleteZakatLiability(liabilityId);
    return liabilityId;
  }
);

export const updateZakatPaymentAmount = createAction<number, string>(
  UPDATE_ZAKAT_PAYMENT_AMOUNT
);

export const updateZakatTopUp = createAction<number, string>(
  UPDATE_ZAKAT_TOP_UP
);

import { combineReducers, AnyAction } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { portfolioDataReducer } from "./portfolioData/reducers";
import authReducer from "./auth/auth.reducer";
import { zakatReducer } from "./reducers/reducers";
import { zakatReducerV2 } from "./zakatV2/zakat.reducer";
import resourcesReducer from "./resources";
import { charityReducer, charityReducerV2 } from "./charity/charity.reducer";
import { investorReducer } from "./investor/investor.reducer";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";
import { logoutAction } from "@components/Login/actions";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["isAuthenticated"],
};

const reducersV1 = {
  auth: persistReducer(authPersistConfig, authReducer),
  portfolioData: portfolioDataReducer,
  zakat: zakatReducer,
  resources: resourcesReducer,
  charity: charityReducer,
};

export const reducersV2 = {
  auth: persistReducer(authPersistConfig, authReducer),
  zakat: zakatReducerV2,
  investor: investorReducer,
  charity: charityReducerV2,
};

const reducers = isZakat2025Enabled ? reducersV2 : reducersV1;

const appReducer = combineReducers(reducers);

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logoutAction.fulfilled.type) {
    state = undefined; // Reset the state
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export type RootStateV2 = {
  [K in keyof typeof reducersV2]: ReturnType<(typeof reducersV2)[K]>;
};

export default rootReducer;

import {
  BankAccountInterface,
  BankAccountItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";

export type BankPayload = {
  payload: BankAccountItemInterface;
};

const sumAll = (list: BankAccountItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + (item.cashInAccount || 0), 0);
};

export const deleteBankAccountReducer = (
  state: PortfolioDataInterface,
  { payload }: BankPayload
): void => {
  if (!state.bankAccounts) {
    state.bankAccounts = {
      totalValue: 0,
      bankAccountList: [],
    };
  }

  state.bankAccounts.bankAccountList =
    state.bankAccounts.bankAccountList?.filter(
      (account) => account.id !== payload.id
    );

  state.bankAccounts.totalValue = sumAll(state.bankAccounts.bankAccountList);
};

export const updateBankAccountReducer = (
  state: PortfolioDataInterface,
  { payload }: BankPayload
): void => {
  if (!state.bankAccounts) {
    state.bankAccounts = {
      totalValue: 0,
      bankAccountList: [],
    };
  }

  const debtExist = state.bankAccounts.bankAccountList?.some(
    (account) => account.id === payload.id
  );

  if (debtExist) {
    state.bankAccounts.bankAccountList =
      state.bankAccounts.bankAccountList?.map((account) =>
        account.id === payload.id ? payload : account
      );
  } else {
    state.bankAccounts.bankAccountList?.push(payload);
  }

  state.bankAccounts.totalValue = sumAll(state.bankAccounts.bankAccountList);
};

export type BulkUpdatePayload = {
  payload: BankAccountInterface;
};

export const bulkUpdateBankAccount = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.bankAccounts = payload;
};

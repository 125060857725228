import { TwoFactorAuthMethod } from "@ifgengineering/client-auth-sdk";
import React, { FC, useState } from "react";
import GetEmailForm from "./GetEmailForm";
import MagicCodeForm from "./MagicCodeForm";

interface MagicCodeProps {
  loginRedirect: string;
  signupURL?: string;
  handleCancel: () => void;
  method?: TwoFactorAuthMethod;
  is2FAFlow?: boolean;
  customErrorMessage?: string;
}

const MagicCode: FC<MagicCodeProps> = ({
  handleCancel,
  signupURL,
  loginRedirect,
  method,
  is2FAFlow,
  customErrorMessage,
}) => {
  const [showMagicCodeForm, setShowMagicCodeForm] = useState(
    is2FAFlow || false
  );
  const [email, setEmail] = useState("");

  const handleMagicCodeBackButton = () => {
    is2FAFlow ? handleCancel() : setShowMagicCodeForm(false);
  };

  return showMagicCodeForm ? (
    <MagicCodeForm
      loginRedirect={loginRedirect}
      email={email}
      handleBackButton={handleMagicCodeBackButton}
      method={method}
      customErrorMessage={customErrorMessage}
    />
  ) : (
    <GetEmailForm
      handleBackButton={handleCancel}
      signupURL={signupURL}
      email={email}
      onChange={(email) => setEmail(email)}
      handleSuccess={() => setShowMagicCodeForm(true)}
    />
  );
};

export default MagicCode;

import React, { FC, useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import {
  InputsContainer,
  TimerContainer,
  TitleSpacer,
  TryADifferentMethodContainer,
} from "./styled";
import MagicCodeInput from "./MagicCodeInput";
import {
  FilledButton as Button,
  Text,
  theme,
} from "@ifgengineering/component-library";
import axios from "axios";
import { toast } from "react-toastify";
import { BackContainer, Container } from "../styled";
import Icon from "@icon-park/react/es/all";
import { magicCodeSchema } from "./validationSchemas";
import { navigate } from "gatsby";
import useInterval from "./useInterval";
import { AuthComponentsContext } from "../../../Context";
import { COUNTDOWN_TIME_IN_SECONDS } from "../../../../consts";
import { useDispatch } from "react-redux";

export type TwoFactorAuthMethod = "email" | "mobile";

interface MagicCodeFormProps {
  loginRedirect: string;
  email: string;
  handleBackButton: () => void;
  method?: TwoFactorAuthMethod;
  customErrorMessage?: string;
}

const COUNT_TIME = COUNTDOWN_TIME_IN_SECONDS * 1000;
const DELAY = 1000;

export const MagicCodeForm: FC<MagicCodeFormProps> = ({
  loginRedirect,
  handleBackButton,
  email,
  method = "email",
  customErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(customErrorMessage || "");
  const [showResentOtpMessage, setShowResentOtpMessage] = useState(false);
  const [allowSendCode, setAllowSendCode] = useState(false);
  const [formattedTime, setFormattedTime] = useState("01:00");
  const [count, setCount] = useState<number>(COUNT_TIME);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [mfaMethod, setMfaMethod] = useState<TwoFactorAuthMethod>(method);
  const dispatch = useDispatch();

  const {
    useAnalytics,
    passwordlessVerify,
    passwordlessStart,
    logException,
    generateOTP,
    loginAction,
    is2FAEnabled,
    is2FAFlow,
    emailPassword,
  } = useContext(AuthComponentsContext);

  useInterval(
    () => {
      setCount(count - 1000);
    },
    isPlaying ? DELAY : null
  );

  useEffect(() => {
    setPlaying(true);
  }, []);

  useEffect(() => {
    const minutes = Math.floor(count / 60000);
    const seconds = Math.floor((count % 60000) / 1000);
    const padded = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
    setFormattedTime(padded);

    if (count <= 0) {
      setPlaying(false);
      setAllowSendCode(true);
    }
  }, [count]);

  const fireAnalytics = useAnalytics("login");

  const handleLoginWithMagicCode = async (otp: string) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      (await fireAnalytics)({
        email: email.toLowerCase(),
        method: "passwordless",
      });
      await passwordlessVerify(email, otp);

      navigate(loginRedirect);
    } catch (e: any) {
      setIsLoading(false);
      if (axios.isAxiosError(e) && e.response) {
        if (e.response?.status === 401) {
          setErrorMessage("Wrong code. Please try again");
          return;
        }
      }

      toast.error("Something went wrong. Try again or contact support");

      logException({
        email,
        tag: "passwordlessVerify",
        exception: e,
      });

      throw e;
    }
  };

  const resendMagicCode = async () => {
    if (!allowSendCode) return;
    const is2FA = is2FAEnabled && is2FAFlow;

    try {
      if (is2FA) {
        await generateOTP(emailPassword.email, mfaMethod);
      } else {
        await passwordlessStart(email);
      }
      fadeResentOtpMessage();
    } catch (e: any) {
      if (e.response.status !== 404) {
        logException({
          email,
          tag: is2FA ? "generateOTP" : "passwordlessStart",
          exception: e,
        });
      }
    }
  };

  const back = () => {
    handleBackButton();
  };

  const fadeResentOtpMessage = () => {
    setShowResentOtpMessage(true);

    setTimeout(() => {
      setCount(COUNT_TIME);
      setPlaying(true);
      setAllowSendCode(false);
      setShowResentOtpMessage(false);
    }, 3000);
  };

  const otherMethod = mfaMethod === "mobile" ? "email" : "mobile";
  const otherMethodLabel = otherMethod === "mobile" ? "SMS" : "email";

  const handleLoginWith2FA = async (otp: string) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      const res = await dispatch(
        loginAction({
          ...emailPassword,
          otp,
        })
      );

      if (res?.payload?.message == "Login successful") {
        navigate(loginRedirect);
      }

      if (res.error?.message?.includes("401")) {
        setErrorMessage("Invalid or expired magic code.");
        setIsLoading(false);
      }
    } catch (e: any) {
      setIsLoading(false);
      setErrorMessage("Something went wrong. Contact support");
      logException({
        email,
        tag: "loginWith2FA",
        exception: errorMessage,
      });
    }
  };

  const handleSubmit = async ({ otp }: { otp: string }) => {
    setErrorMessage("");
    if (is2FAEnabled && is2FAFlow) {
      await handleLoginWith2FA(otp);
    } else {
      await handleLoginWithMagicCode(otp);
    }
  };

  const handleDifferent2FAMethod = async () => {
    setMfaMethod(otherMethod);
    fadeResentOtpMessage();
    await generateOTP(email, otherMethod);
  };

  return (
    <div data-testid="otp">
      <BackContainer onClick={back}>
        <Icon
          type="ArrowLeft"
          size={18}
          theme="outline"
          fill={theme.colors.BLUE600}
        />
        <Text type="S16" color="BLUE600" fontFamily="Inter">
          Back
        </Text>
      </BackContainer>
      <TitleSpacer>
        <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
          Check your {mfaMethod} for Magic code
        </Text>
      </TitleSpacer>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={magicCodeSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, submitForm, isValid }) => {
          const formikErrorMessage = touched.otp ? errors.otp : undefined;
          const errMessage = errorMessage || formikErrorMessage;

          return (
            <>
              <Text type="S16" color="SLATE800" fontFamily="Inter">
                Magic Code
              </Text>
              <InputsContainer>
                <MagicCodeInput
                  name="otp"
                  numInputs={6}
                  isDisabled={isLoading}
                  hasErrored={Boolean(errMessage)}
                  errorMessage={errMessage}
                />
              </InputsContainer>
              <Container gap={16}>
                {mfaMethod === "email" && (
                  <Text type="P16" color="SLATE800">
                    {"We've sent a code to: "}
                    <Text
                      type="H16"
                      color="SLATE800"
                      as="a"
                      href={`mailto:${email}`}
                    >
                      {email}
                    </Text>
                  </Text>
                )}
                <Button
                  testId="login-button"
                  type="submit"
                  height="56px"
                  text="Login"
                  color="darkBlue"
                  isLoading={isLoading}
                  disabled={!isValid || isLoading}
                  onClick={submitForm}
                />
              </Container>

              <div>
                <br />
                {showResentOtpMessage ? (
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Magic Code sent!
                  </Text>
                ) : (
                  <>
                    <TimerContainer>
                      <Text type="S16" color="SLATE600" fontFamily="Inter">
                        {formattedTime}
                      </Text>
                    </TimerContainer>
                    <Text
                      type="S16"
                      color={allowSendCode ? "BLUE600" : "SLATE450"}
                      fontFamily="Inter"
                      style={{ cursor: allowSendCode ? "pointer" : "" }}
                      onClick={resendMagicCode}
                    >
                      Resend Magic Code
                    </Text>
                  </>
                )}
              </div>
              {mfaMethod !== "email" && (
                <TryADifferentMethodContainer>
                  <Text
                    type="S16"
                    fontFamily="Inter"
                    color={"BLUE600"}
                    style={{ cursor: "pointer" }}
                    onClick={handleDifferent2FAMethod}
                  >
                    Send magic code via {otherMethodLabel}
                  </Text>
                  <Icon type="RightSmall" fill={theme.colors.BLUE600} />
                </TryADifferentMethodContainer>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default MagicCodeForm;

import { ZakatCharityDetailedInterface } from "@ifgengineering/hip-app-domain/src";
import { createReducer } from "@reduxjs/toolkit";
import {
  createUpdateCharityAllocation,
  fetchCharityAllocationAPI,
} from "../actions/charityAllocation";
import {
  createZakatAPI,
  fetchZakatAPI,
  findOrCreateZakatAPI,
  updateZakatAPI,
} from "../actions/zakat";
import { fetchZakatCharityAPI } from "../actions/zakatCharity";
import { ZakatAllocationObject } from "../types";
import { createUpdateCharityAllocationReducer } from "./createUpdateCharityAllocationReducer";

export interface ZakatGiftAid {
  id?: number;
  title: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postCode: string;
}
export interface ZakatState {
  id?: number;
  topUp?: number;
  amountDue?: number;
  nisab?: number;
  amountAllocated?: number;
  allocation?: [];
  giftAid?: ZakatGiftAid;
  charities: ZakatCharityDetailedInterface[];
  charityAllocations: ZakatAllocationObject[];
}

const initialState: ZakatState = {
  charities: [],
  charityAllocations: [],
};

export const zakatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchZakatAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        ...payload,
      };
      return state;
    })
    .addCase(createZakatAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        ...payload,
      };
      return state;
    })
    .addCase(findOrCreateZakatAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        ...payload,
      };
      return state;
    })
    .addCase(updateZakatAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        ...payload,
      };
      return state;
    })
    .addCase(fetchCharityAllocationAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        charityAllocations: payload || [],
      };
      return state;
    })
    .addCase(fetchZakatCharityAPI.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        charities: payload || [],
      };
      return state;
    })
    .addCase(
      createUpdateCharityAllocation,
      createUpdateCharityAllocationReducer
    );
});

import { InitializeApi } from "@ifgengineering/client-auth-sdk";
import { CORE_API, CUR8_API } from "gatsby-env-variables";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";
import axios from "axios";
const serverURL = isZakat2025Enabled ? CUR8_API : CORE_API;

const authSdk = InitializeApi({
  serverURL,
  appAxios: axios as any,
});

export default authSdk;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as actions from "../constants/actionTypes";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import axios from "axios";
import { APP_NAME, CORE_API } from "gatsby-env-variables";
import authSdk from "../authSDK";
import { logException } from "@helpers/logException";
import { AppleCallbackInput } from "@ifgengineering/client-auth-sdk/src/types";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const isZakatApp = APP_NAME === "Zakat";
const isZakatAppAnd2025Enabled = isZakatApp && isZakat2025Enabled;

export const clearStore = createAction(actions.CLEAR_STORE);

export const UPDATE_APP_RECORD = "UPDATE_APP_RECORD";

export const updateUserInfo = createAction<any, string>(
  actions.UPDATE_USER_INFO
);

export const updateAppRecord = createAction<{
  app: string;
}>(UPDATE_APP_RECORD);

export const authError = createAsyncThunk<
  void,
  {
    err: Error;
  }
>(actions.AUTH_ERROR, () => {
  toast.info("Please log in");
});

export const loginAction = createAsyncThunk<
  any,
  {
    email: string;
    password: string;
    captchaToken?: string;
    otp?: string;
  }
>(actions.LOGIN, async ({ email, password, captchaToken, otp }) => {
  try {
    const loginPayload = { email, password, captchaToken, otp };

    const result = await authSdk.login(loginPayload);

    return result.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response?.status === 422) {
        toast.info("Please set a new password below");
        throw e;
      }

      if (e.response?.status === 401) {
        throw e;
      }
    }

    toast.error("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "login",
      exception: e,
    });

    throw e;
  }
});

export const fetchUserInfoAPI = createAsyncThunk<
  any,
  void,
  {
    rejectValue: string;
  }
>(actions.FETCH_USER_INFO, async () => {
  try {
    const response = await authSdk.userInfo();

    if (isZakatAppAnd2025Enabled) {
      return response.user;
    }

    return { data: response.user };
  } catch (e: any) {
    if (e.response.status !== 401) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "userInfo",
        exception: e,
      });

      throw Error(e);
    }

    return { errors: e.networkError.result.errors };
  }
});

export const signupAction = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    captchaToken?: string;
    signupRedirect: string;
  }
>(
  actions.SIGN_UP,
  async ({ email, password, captchaToken, signupRedirect }) => {
    const origin = window.location.origin;
    const signupPayload = isZakatAppAnd2025Enabled
      ? { email, password, captchaToken }
      : { email, password, origin, captchaToken };

    try {
      await authSdk.signup(signupPayload);

      toast.success(
        "Your account has been successfully created! Please sign in with your new login details"
      );

      navigate(signupRedirect);

      return;
    } catch (e: any) {
      if (e.response.status !== 409) {
        toast.error("There was a problem creating your user, please try again");

        logException({
          email,
          tag: "signup",
          exception: e,
        });
      }

      return e;
    }
  }
);

export const logoutAction = createAsyncThunk<void>(actions.LOGOUT, async () => {
  try {
    await authSdk.logout();

    navigate("/auth/login/");
  } catch (e) {
    logException({
      tag: "logout",
      exception: e,
    });

    toast.error("There was an unexpected error logging out");
    throw e;
  }
});

export const isLoggedInAction = createAsyncThunk<any>(
  actions.IS_LOGGED_IN,
  async () => {
    try {
      const res = await axios.get(`${CORE_API}/auth/is-logged-in`);
      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Login failed, please try again");
      navigate("/auth/login");
      throw e;
    }
  }
);

export const sendResetPasswordLink = async (email: string) => {
  const origin = window.location.origin;
  await authSdk.forgotPassword(email, origin);
};

export const resetPassword = async ({
  email,
  newPassword,
  token,
}: {
  email: string;
  newPassword: string;
  token: string;
}): Promise<void> => {
  return await authSdk.resetPassword({ email, newPassword, token });
};

export const verifyEmail = async ({
  email,
  token,
}: {
  email: string;
  token: string;
}): Promise<void> => {
  return authSdk.verifyEmail({ email, token });
};

export const changePassword = async ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}): Promise<void> => {
  await authSdk.changePassword({ password, newPassword });
};

export const signInGoogleAction = createAsyncThunk<any, any>(
  actions.SIGN_IN_GOOGLE,
  async ({ authorizationCode }, { rejectWithValue }) => {
    try {
      await authSdk.googleLogin({
        authorizationCode,
      });
      localStorage.removeItem("apple_id_token");
      isZakatApp && navigate("/app/calculate");
    } catch (e) {
      logException({
        tag: "signInGoogle",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const signInAppleAction = createAsyncThunk<any, AppleCallbackInput>(
  actions.SIGN_IN_APPLE,
  async ({ authorizationCode, appleIdToken }, { rejectWithValue }) => {
    try {
      const response = await authSdk.appleCallback({
        authorizationCode,
        appleIdToken,
      });
      const isPrivateRelayEmail = response.data?.isPrivateRelayEmail;
      const email = response.data?.email;
      if (isPrivateRelayEmail) {
        localStorage.removeItem("apple_id_token");
        navigate("/app/signup-apple");
        return { isPrivateRelayEmail };
      }

      if (email) {
        localStorage.removeItem("apple_id_token");
        isZakatApp && navigate("/app/calculate");
        return { email };
      }
    } catch (e) {
      logException({
        tag: "signInApple",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

export const signupAppleAction = createAsyncThunk<any, any>(
  actions.SIGN_UP_APPLE,
  async ({ authorizationCode }, { rejectWithValue }) => {
    try {
      const response = await authSdk.appleSignup({
        authorizationCode,
      });
      if (response.data?.email) {
        localStorage.removeItem("apple_id_token");
        isZakatApp && navigate("/app/calculate");
      }
    } catch (e) {
      logException({
        tag: "signupApple",
        exception: e,
      });
      toast.error("Something went wrong! Try again later.");
      return rejectWithValue(e);
    }
  }
);

import {
  CryptoInterface,
  CryptoItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";

export type CryptoReducerPayload = {
  payload: CryptoItemInterface;
};

const sumAll = (cryptoList: CryptoItemInterface[]) =>
  cryptoList.reduce((acc, crypto: CryptoItemInterface) => {
    return acc + (crypto.totalValue || 0);
  }, 0);

export const updateCryptoReducer = (
  state: PortfolioDataInterface,
  { payload }: CryptoReducerPayload
): void => {
  if (!state.crypto) {
    state.crypto = {
      totalValue: 0,
      cryptoList: [],
    };
  }

  const commodityAlreadyListed = state.crypto.cryptoList?.some(
    (cryptoList) => cryptoList.id === payload.id
  );

  if (commodityAlreadyListed) {
    const cryptoList =
      state.crypto.cryptoList?.map((cryptoList) => {
        return cryptoList.id === payload.id ? payload : cryptoList;
      }) || [];

    state.crypto.cryptoList = cryptoList;
  } else {
    state.crypto.cryptoList?.push(payload);
  }

  state.crypto = {
    ...state.crypto,
    totalValue: sumAll(state.crypto.cryptoList || []),
  };
};

export const deleteCryptoReducer = (
  state: PortfolioDataInterface,
  { payload }: CryptoReducerPayload
): void => {
  if (!state.crypto) {
    state.crypto = {
      totalValue: 0,
      cryptoList: [],
    };
  }

  const cryptoList =
    state.crypto.cryptoList?.filter(
      (cryptoList) => cryptoList.id !== payload.id
    ) || [];

  state.crypto = {
    cryptoList,
    totalValue: sumAll(cryptoList),
  };
};

export type BulkUpdatePayload = {
  payload: CryptoInterface;
};

export const bulkUpdateCrypto = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.crypto = payload;
};

import { createReducer } from "@reduxjs/toolkit";
import {
  createPartnerCharityDonation,
  fetchPartnerCharityDonation,
  updateCharityDonation,
  updateCharityStore,
  fetchCharityByKeyAPI,
} from "@components/Charity/actions";
import {
  PartnerCharityDetailedInterface,
  PartnerCharityDonationInterface,
} from "@ifgengineering/hip-app-domain/src";

export type PartnerCharityInterfaceExtra = PartnerCharityDetailedInterface & {
  donation?: PartnerCharityDonationInterface;
  loading?: boolean;
};

export const initialState: PartnerCharityInterfaceExtra = {
  willTemplateCopy: "",
  active: false,
  displayName: "",
  logoKey: "",
  registeredName: "",
  address: {},
};

export const charityReducers = createReducer(initialState, (builder) => {
  builder
    .addCase(updateCharityStore, (state, { payload }) => {
      // if the user access a non-charity domain I want to clear the store
      // as a signal that there is no charity to be loaded.
      if (!payload?.id) {
        state = {};
      }

      state = {
        ...state,
        ...payload,
      };

      return state;
    })
    .addCase(fetchCharityByKeyAPI.pending, (state, { payload }) => {
      state = {
        ...initialState,
        loading: true,
        donation: state?.donation,
      };
      return state;
    })
    .addCase(fetchCharityByKeyAPI.fulfilled, (state, { payload }) => {
      state = {
        ...initialState,
        ...payload,
        donation: state?.donation,
      };
      return state;
    })
    .addCase(fetchPartnerCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: payload,
      };
      return state;
    })
    .addCase(createPartnerCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: {
          ...state.donation,
          ...payload,
        },
      };

      return state;
    })
    .addCase(updateCharityDonation.fulfilled, (state, { payload }) => {
      state = {
        ...state,
        donation: {
          ...state.donation,
          ...payload,
        },
      };

      return state;
    });
});

import {
  OwedAssetItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";
import { OwedAssetObject } from "../constants/types";

export type OwedAssetPayload = {
  payload: OwedAssetItemInterface;
};

const sumAll = (list: OwedAssetItemInterface[] | undefined): number => {
  if (!list) {
    return 0;
  }

  return list.reduce((acc, item) => acc + item.value, 0);
};

export const deleteOwedAssetReducer = (
  state: PortfolioDataInterface,
  { payload }: OwedAssetPayload
): void => {
  if (!state.owedAssets) {
    state.owedAssets = {
      totalValue: 0,
      owedAssetList: [],
    };
  }

  state.owedAssets.owedAssetList = state.owedAssets.owedAssetList?.filter(
    (owedAsset) => owedAsset.id !== payload.id
  );

  state.owedAssets.totalValue = sumAll(state.owedAssets.owedAssetList);
};

export const updateOwedAssetReducer = (
  state: PortfolioDataInterface,
  { payload }: OwedAssetPayload
): void => {
  if (!state.owedAssets) {
    state.owedAssets = {
      totalValue: 0,
      owedAssetList: [],
    };
  }

  const fundExist = state.owedAssets.owedAssetList?.some(
    (owedAsset) => owedAsset.id === payload.id
  );

  if (fundExist) {
    state.owedAssets.owedAssetList = state.owedAssets.owedAssetList?.map(
      (owedAsset) => {
        if (owedAsset.id === payload.id) {
          return payload;
        }

        return owedAsset;
      }
    );
  } else {
    state.owedAssets.owedAssetList?.push(payload);
  }

  state.owedAssets.totalValue = sumAll(state.owedAssets.owedAssetList);
};

export type BulkUpdatePayload = {
  payload: OwedAssetObject;
};

export const bulkUpdateOwed = (
  state: PortfolioDataInterface,
  { payload }: BulkUpdatePayload
): void => {
  state.owedAssets = payload;
};

import { AuthState } from "./types";

export const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  user: {
    id: null,
    email: "",
    appRecord: {
      zakat: false,
      track: false,
      compare: false,
      wills: false,
    },
  },
  error: null,
  neverLoaded: true,
};

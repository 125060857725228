import { FEATURE_FLAGS } from "gatsby-env-variables";
import { initializeFeatureFlag } from "@ifgengineering/client-feature-flag";
import getCookie from "@helpers/getCookie";

const FLAGS = {
  TRACK: "TRACK",
  ONBOARD: "ONBOARD",
  INSIGHTS: "INSIGHTS",
  FINANCIAL_BEHAVIOUR: "FINANCIAL_BEHAVIOUR",
  NEW_ONBOARDING: "NEW_ONBOARDING",
  NEW_SIGN_UP: "NEW_SIGN_UP",
  LOGIN_MAGIC_CODE: "LOGIN_MAGIC_CODE",
  NEW_LIVE_DEALS: "NEW_LIVE_DEALS",
  PENDING_PAYMENTS: "PENDING_PAYMENTS",
  NEW_DEAL_DETAIL: "NEW_DEAL_DETAIL",
  NEW_BANK_DETAIL: "NEW_BANK_DETAIL",
  CRYPTO_PAGE: "CRYPTO_PAGE",
  NEW_ACCOUNT_SETTINGS: "NEW_ACCOUNT_SETTINGS",
  CUR8_IN_WEALTH: "CUR8_IN_WEALTH",
  LAYOUT_B_ENABLED: "LAYOUT_B_ENABLED",
  IDENTITY_ENABLED: "IDENTITY_ENABLED",
  ZAKAT_2025: "ZAKAT_2025",
};

const { useFeatureFlag } = initializeFeatureFlag({
  FEATURE_FLAGS,
  FLAGS_ENUM: FLAGS,
});

export const [
  isCur8InWealthEnabled,
  isPendingPaymentsEnabled,
  isNewAccountSettingsEnabled,
  isNewOnboardingEnabled,
  isNewLiveDealsEnabled,
  isNewDealDetailEnabled,
  isNewBankDetailEnabled,
  isFinancialBehaviourEnabled,
] = useFeatureFlag([
  "CUR8_IN_WEALTH",
  "PENDING_PAYMENTS",
  "NEW_ACCOUNT_SETTINGS",
  "NEW_ONBOARDING",
  "NEW_LIVE_DEALS",
  "NEW_DEAL_DETAIL",
  "NEW_BANK_DETAIL",
  "FINANCIAL_BEHAVIOUR",
]) as boolean[];

export const [
  isOnboardEnabled,
  isCryptoPageEnabled,
  isNewSignupEnabled,
  isInsightsEnabled,
  isLayoutBEnabled,
  isMagicCodeEnabled,
  isTrackEnabled,
  isIdentityPageEnabled,
] = useFeatureFlag([
  "ONBOARD",
  "CRYPTO_PAGE",
  "NEW_SIGN_UP",
  "INSIGHTS",
  "LAYOUT_B_ENABLED",
  "LOGIN_MAGIC_CODE",
  "TRACK",
  "IDENTITY_ENABLED",
]) as boolean[];

const isZakat2025FFEnabled = useFeatureFlag("ZAKAT_2025") as boolean;
const isZakat2025CookieEnabled = getCookie("ZAKAT_2025") === "true";
export const isZakat2025Enabled =
  isZakat2025FFEnabled || isZakat2025CookieEnabled;
